import _pseudomap from "./pseudomap";
import _process from "process";
var exports = {};
var process = _process;
if (process.env.npm_package_name === "pseudomap" && process.env.npm_lifecycle_script === "test") process.env.TEST_PSEUDOMAP = "true";

if (typeof Map === "function" && !process.env.TEST_PSEUDOMAP) {
  exports = Map;
} else {
  exports = _pseudomap;
}

export default exports;